<template>
  <div class="container">
    <a-page-header title="基础信息" :backIcon="false"></a-page-header>
    <div class="wrapper">
      <a-form :form="form" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-tabs :default-active-key="tabsKey" @change="hanldeTabsChange">
          <a-tab-pane :key="1" tab="工商信息">
            <div style="max-width:800px; margin:0 auto;">
              <a-row :gutter="16">
                <a-col :span="12">
                  <a-form-item label="企业名称">
                    <a-input :read-only="true" type="text" v-decorator="['company_name', {rules: [{required: true, message: '请输入企业名称'}]}]" />
                  </a-form-item>
                  <a-form-item label="统一信用代码">
                    <a-input :read-only="true" type="text" v-decorator="['credit_code', {rules: [{required: true, message: '请输入统一信用代码'}]}]" />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="营业执照">
                    <a-upload
                      :disabled="readonly"
                      name="photo"
                      class="yyzz-wrapper"
                      :show-upload-list="false"
                      action="/common/upload"
                      @change="handleChange"
                      v-decorator="['yyzz', {rules: [{required: true, message: '请上传营业执照'}]}]">
                      <img v-if="yyzz" :src="yyzz" alt="营业执照" />
                      <div class="empty" v-else>
                        <a-icon class="icon" :type="loading ? 'loading' : 'picture'" />
                        <div class="upload-text">点击上传图片</div>
                        <div class="upload-text-tip">支持jpg/png格式，不超过2MB</div>
                      </div>
                    </a-upload>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="16">
                <a-col :span="12">
                  <a-form-item label="企业法人">
                    <a-input :read-only="readonly" type="text" v-decorator="['real_name', {rules: [{required: true, message: '请输入企业法人'}]}]" />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="纳税人类型">
                    <a-radio-group :read-only="readonly" v-decorator="['type', {rules: [{required: true, message: '请选择纳税人类型'}]}]">
                      <a-radio v-if="!readonly || userInfo.type == 0" :value="0">一般纳税人</a-radio>
                      <a-radio v-if="!readonly || userInfo.type == 1" :read-only="readonly" :value="1">小规模纳税人</a-radio>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="联系电话">
                    <a-input :read-only="readonly" type="text" v-decorator="['phone', {rules: [{required: true, message: '请输入联系电话'}]}]" />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="行业类别">
                    <a-input :read-only="readonly" type="text" v-decorator="['industry_type', {rules: [{required: true, message: '请输入行业类别'}]}]" />
                  </a-form-item>
                </a-col>
                <!-- <a-col :span="12">
                  <a-form-item label="企业状态">
                    <a-input :read-only="readonly" type="text" v-decorator="['company_status', {rules: [{required: true, message: '请输入企业状态'}]}]" />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="注册资金">
                    <a-input :read-only="readonly" type="text" v-decorator="['money', {rules: [{required: true, message: '请输入注册资金'}]}]" />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="实缴资金">
                    <a-input :read-only="readonly" type="text" v-decorator="['real_money', {rules: [{required: true, message: '请输入实缴资金'}]}]" />
                  </a-form-item>
                </a-col> -->
                <a-col :span="12">
                  <a-form-item label="注册地址">
                    <a-input :read-only="readonly" type="text" v-decorator="['address', {rules: [{required: true, message: '请输入注册地址'}]}]" />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="开户银行">
                    <a-input :read-only="readonly" type="text" v-decorator="['bank', {rules: [{required: true, message: '请输入开户银行，填写到支行'}]}]" />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="银行账号">
                    <a-input :read-only="readonly" type="text" v-decorator="['bank_code', {rules: [{required: true, message: '请输入银行账号'}]}]" />
                  </a-form-item>
                </a-col>
                <!-- <a-col :span="12">
                  <a-form-item label="上市状态">
                    <a-input :read-only="readonly" type="text" v-decorator="['listed_status', {rules: [{required: true, message: '请输入上市状态'}]}]" />
                  </a-form-item>
                </a-col> -->
              </a-row>
            </div>
          </a-tab-pane>
          <a-tab-pane :key="2" tab="联络信息">
            <div style="max-width:800px; margin:0 auto;">
              <a-form-item label="企业联系人">
                <a-row :gutter="16">
                  <a-col :span="12">
                    <a-input :read-only="readonly" :placeholder="readonly ? '' : '请输入企业联系人姓名'" type="text" v-decorator="['yq_name', {rules: [{required: true, message: '请输入企业联系人姓名'}]}]" />
                  </a-col>
                  <a-col :span="12">
                    <a-input :read-only="readonly" :placeholder="readonly ? '' : '输入企业联系人电话号码'" type="text" v-decorator="['yq_phone', {rules: [{required: true, message: '请输入企业联系人电话号码'}]}]" />
                  </a-col>
                  <!-- <a-col :span="24">
                    <a-input :read-only="readonly" :placeholder="readonly ? '' : '输入企业联系人邮箱'" type="text" v-decorator="['yq_email', {rules: [{required: true, message: '请输入企业联系人邮箱'}]}]" />
                  </a-col> -->
                </a-row>
              </a-form-item>
              <a-form-item label="财务联系人">
                <a-row :gutter="16">
                  <a-col :span="12">
                    <a-input :read-only="readonly" :placeholder="readonly ? '' : '输入财务联系人姓名'" type="text" v-decorator="['cw_name', {rules: [{required: true, message: '请输入财务联系人姓名'}]}]" />
                  </a-col>
                  <a-col :span="12">
                    <a-input :read-only="readonly" :placeholder="readonly ? '' : '输入财务联系人电话号码'" type="text" v-decorator="['cw_phone', {rules: [{required: true, message: '请输入财务联系人电话号码'}]}]" />
                  </a-col>
                  <!-- <a-col :span="24">
                    <a-input :read-only="readonly" :placeholder="readonly ? '' : '输入财务联系人邮箱'" type="text" v-decorator="['cw_email', {rules: [{required: true, message: '请输入财务联系人邮箱'}]}]" />
                  </a-col> -->
                </a-row>
              </a-form-item>
              <a-form-item label="邮寄地址">
                <a-row :gutter="16">
                  <a-col :span="12">
                    <a-input :read-only="readonly" :placeholder="readonly ? '' : '输入收件人姓名'" type="text" v-decorator="['yj_name', {rules: [{required: true, message: '请输入收件人姓名'}]}]" />
                  </a-col>
                  <a-col :span="12">
                    <a-input :read-only="readonly" :placeholder="readonly ? '' : '输入收件人电话号码'" type="text" v-decorator="['yj_phone', {rules: [{required: true, message: '请输入收件人电话号码'}]}]" />
                  </a-col>
                  <a-col :span="24">
                    <a-input :read-only="readonly" :placeholder="readonly ? '' : '输入收件地址'" type="text" v-decorator="['yj_address', {rules: [{required: true, message: '请输入收件地址'}]}]" />
                  </a-col>
                </a-row>
              </a-form-item>
              <a-form-item label="通知邮箱">
                <a-input :read-only="readonly" :placeholder="readonly ? '' : '输入接收通知邮箱'" type="text" v-decorator="['eamil', {rules: [{required: true, message: '请输入接收通知邮箱'}]}]" />
              </a-form-item>
            </div>
          </a-tab-pane>
          <a-tab-pane :key="3" tab="服务信息" v-if="userInfo.role_id == 2">
            <div style="max-width:600px; margin:0 auto;">
              <!-- <a-form-item label="单笔限额上限(人/元)">
                <a-input
                  type="text"
                  v-decorator="['d_money', {rules: [{required: true, message: '请输入单笔限额上限'}]}]"
                />
              </a-form-item>
              <a-form-item label="每日限额上限(人/元)">
                <a-input
                  type="text"
                  v-decorator="['day_money', {rules: [{required: true, message: '请输入每日限额上限'}]}]"
                />
              </a-form-item> -->
              <a-form-item label="月度限额上限(人/元)">
                <a-input
                 :read-only="readonly"
                  type="text"
                  v-decorator="['month_money', {rules: [{required: true, message: '请输入月度限额上限'}]}]"
                />
              </a-form-item>
              <!-- <a-form-item label="年度限额上限(人/元)">
                <a-input
                  type="text"
                  v-decorator="['year_money', {rules: [{required: true, message: '请输入年度限额上限'}]}]"
                />
              </a-form-item> -->
              <a-form-item label="含税服务费率">
                <a-input
                 :read-only="readonly"
                  type="text"
                  v-decorator="['hs_money', {rules: [{required: true, message: '请输入含税服务费率'}]}]"
                  suffix="%"
                />
              </a-form-item>
              <a-form-item label="服务商标签">
                <div v-if="readonly">
                  <template v-for="item in lableData">
                    <a-tag :key="item.id" v-if="item.checked">{{item.name}}</a-tag>
                  </template>
                </div>
                <a-checkbox-group @change="handleLableChange" v-model="checkedLable" v-else>
                  <a-checkbox :value="item.id" v-for="item in lableData" :key="item.id">{{item.name}}</a-checkbox>
                </a-checkbox-group>
                <a-input
                  type="hidden"
                  v-decorator="['lable', {rules: [{required: true, message: '请输入服务商标签'}]}]"
                />
              </a-form-item>
            </div>
          </a-tab-pane>
          <a-tab-pane :key="4" tab="服务类型" v-if="userInfo.role_id == 2">
            <a-form-item>
              <a-button type="primary" @click="editGoods({}, 0)">添加类目</a-button>
            </a-form-item>
            <a-table row-key="id" :columns="columns" :data-source="goodsList" :pagination="false">
              <template slot="tax" slot-scope="text">
                {{text | formateTax()}}
              </template>
              <template slot="operate" slot-scope="text, item">
                <a-button size="small" type="primary" @click="editGoods(item, item.parent_id)">编辑</a-button>&nbsp;
                <a-popconfirm :title="item.parent_id == 0 ?'确定删除该类目以及其下所有类型吗？' : '确定删除该类型吗？'" placement="topLeft" ok-text="确定" cancel-text="取消" @confirm="deleteGoods(item)">
                  <a-button size="small" type="danger">删除</a-button>
                </a-popconfirm>&nbsp;
                <a-button size="small" v-if="item.parent_id == 0" type="primary" @click="editGoods({}, item.id)">添加类型</a-button>
              </template>
            </a-table>
          </a-tab-pane>

        </a-tabs>
        <div class="footer-item" v-if="tabsKey != 4">
          <div class="footer" v-if="readonly">
            <a-button type="primary" @click="handleStatusChange">编辑</a-button>
          </div>
          <div class="footer" v-else>
            <a-popconfirm title="确定要保存吗？" placement="bottomRight" ok-text="确定" cancel-text="取消" @confirm="handleSubmit">
              <a-button type="primary">保存</a-button>
            </a-popconfirm>
            <a-popconfirm title="确定放弃已修改的内容吗？" placement="bottomRight" ok-text="确定" cancel-text="取消" @confirm="handleCancel">
              <a-button style="margin-left: 10px;">取消</a-button>
            </a-popconfirm>
          </div>
        </div>
      </a-form>
    </div>
    <a-modal v-model="showGoodsPopup" :title="popupType" width="500px" @ok="handleSubmitGoods" @cancel="handleCancelGoods">
      <a-form :labelCol="{ lg: { span: 6 }, sm: { span: 6 } }" :wrapperCol="{ lg: { span: 18 }, sm: { span: 18 } }">
        <a-form-item :label="popupFormData.p_id ? '类型' : '类目'">
          <a-input type="text" v-model="popupFormData.name" />
        </a-form-item>
        <a-form-item label="名称" v-if="popupFormData.p_id">
          <a-input type="text" v-model="popupFormData.title" />
        </a-form-item>
        <a-form-item label="税收编码" v-if="popupFormData.p_id">
          <a-input type="text" v-model="popupFormData.code" />
        </a-form-item>
        <a-form-item label="税率" v-if="popupFormData.p_id">
          <a-input type="text" v-model="popupFormData.tax" suffix="%" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { userInfo, getGoods, lableData } from '@/utils/api'
const columns = [
  {
    title: '编码',
    dataIndex: 'code'
  },
  {
    title: '类目、类型',
    dataIndex: 'name'
  },
  {
    title: '名称',
    dataIndex: 'title'
  },
  {
    title: '税率',
    dataIndex: 'tax',
    scopedSlots: { customRender: 'tax' },
  },
  {
    title: '操作',
    dataIndex: 'operate',
    scopedSlots: { customRender: 'operate' },
  }
]
export default {
  name: 'AccountInfo',
  data() {
    return {
      userInfo: {},
      yyzz: '',
      labelCol: { lg: { span: 8 }, sm: { span: 8 } },
      wrapperCol: { lg: { span: 16 }, sm: { span: 16 } },
      form: this.$form.createForm(this),
      readonly: true,
      loading: false,
      tabsKey: 1,
      goodsList: [],
      columns,
      showGoodsPopup: false,
      popupFormData: {
        id: '',
        parent_id: '',
        name: '',
        title: '',
        code: '',
        tax: '',
        p_id: ''
      },
      popupType: '',
      lableData: [],
      lable: '',
      checkedLable: [],
    }
  },
  filters: {
    formateTax(val) {
      val = parseFloat(val)
      if (val + '' != '0' && val + '' != 'NaN') {
        return val * 100 + '%'
      }
      return ''
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    getLableData () {
      lableData({
        type: 2,
        switch: 4
      }).then((res) => {
        const data = res.info || []
        let checkedLable = this.userInfo.lable
        const checkedLableArr = []
        if (checkedLable && data.length > 0) {
          const lableArr = checkedLable.split(',')
          for (let index = 0; index < data.length; index++) {
            const item = data[index];
            for (let i = 0; i < lableArr.length; i++) {
              const lable = lableArr[i];
              if (item.id == lable) {
                data[index].checked = true
                checkedLableArr.push(item.id)
                break
              }
            }
          }
        }
        this.lableData = data
        this.checkedLable = checkedLableArr
      })
    },
    handleLableChange (e) {
      let lable = ''
      if (e.length > 0) {
        lable = ','
        for (let index = 0; index < e.length; index++) {
          const item = e[index];
          lable += item + ','
        }
      }
      this.form.setFieldsValue({
        lable
      })
    },
    getUserInfo() {
      userInfo({
        switch: 1
      }).then((res) => {
        this.userInfo = res.info
        this.setFormDefaultValue(res.info)
        if (res.info.role_id == 2) {
          this.getGoodsList()
          this.getLableData()
        }
      })
    },
    getGoodsList() {
      getGoods({
        switch: 4
      }).then((res) => {
        this.goodsList = res.info
      })
    },
    editGoods(data={}, p_id) {
      if (data.id) {
        if (p_id && p_id > 0) {
          this.popupType = '编辑类型'
        }else {
          this.popupType = '编辑类目'
        }
      } else {
        if (p_id && p_id > 0) {
          this.popupType = '添加类型'
        }else {
          this.popupType = '添加类目'
        }
      }
      this.popupFormData = {
        name: data.name || '',
        title: data.title || '',
        code: data.code || '',
        tax: data.tax || '',
        id: data.id || '',
        parent_id: data.parent_id || '',
        p_id
      }
      this.showGoodsPopup = true
    },
    handleCancelGoods() {
      this.popupFormData = {
        id: '',
        parent_id: '',
        name: '',
        title: '',
        code: '',
        tax: '',
        p_id: ''
      }
      this.showGoodsPopup = false
      this.popupType = ''
    },
    handleSubmitGoods() {
      const popupFormData = {...this.popupFormData}
      console.log(popupFormData)
      const params = {}
      if (!popupFormData.name) {
        this.$error({
          title: '提示',
          content: '请输入'+ (popupFormData.p_id ? '类型' : '类目'),
          okText: '确定'
        })
        return
      }
      params.name = popupFormData.name
      if (popupFormData.p_id) {
        params.parent_id = popupFormData.p_id
        if (!popupFormData.title) {
          this.$error({
            title: '提示',
            content: '请输入名称',
            okText: '确定'
          })
          return
        }
        params.title = popupFormData.title
        if (!popupFormData.code) {
          this.$error({
            title: '提示',
            content: '请输入税收编码',
            okText: '确定'
          })
          return
        }
        params.code = popupFormData.code
        if (!popupFormData.tax || parseFloat(popupFormData.tax) + '' == 'NaN') {
          this.$error({
            title: '提示',
            content: '请输入正确的税率',
            okText: '确定'
          })
          return
        }
        params.tax = parseFloat(popupFormData.tax) / 100
      }
      if (popupFormData.id) {
        params.id = popupFormData.id
        params.switch = 2
      } else {
        params.switch = 1
      }
      const _this = this
      getGoods(params).then((res) => {
        if (res.error == 0) {
          _this.$success({
            title: '提示',
            content: '操作成功',
            okText: '确定',
            onOk() {
              _this.getGoodsList()
              _this.handleCancelGoods()
            }
          })
        }
      })
    },
    deleteGoods(item) {
      const _this = this
      getGoods({
        switch: 3,
        id: item.id,
        parent_id: item.parent_id
      }).then((res) => {
        if (res.error == 0) {
          this.$success({
            title: '提示',
            content: '删除成功',
            okText: '确定',
            onOk() {
              _this.getGoodsList()
            }
          })
        }
      })
    },
    setFormDefaultValue(userInfo=null) {
      userInfo = userInfo || this.userInfo
      this.yyzz = userInfo.yyzz
      for (const key in userInfo) {
        if (Object.hasOwnProperty.call(userInfo, key)) {
          let item = userInfo[key]
          if (key == 'hs_money') {
            console.log(item)
            item = item ? (parseFloat(item) * 100) : ''
          }
          this.form.getFieldDecorator(key, {initialValue: item})
        }
      }
    },
    handleStatusChange() {
      this.readonly = !this.readonly
    },
    hanldeTabsChange(val) {
      this.tabsKey = val
      this.setFormDefaultValue(this.userInfo)
    },
    handleChange(res) {
      if (res.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (res.file.status === 'done') {
        this.yyzz = res.file.response.info
        this.form.getFieldDecorator('yyzz', {initialValue: res.file.response.info})
        this.loading = false
      }
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.yyzz = this.yyzz
          values.hs_money = values.hs_money ? (parseFloat(values.hs_money) / 100) : ''
          userInfo({
            ...values,
            switch: 2
          }).then(() => {
            this.$success({
              title: '提示',
              content: '保存成功',
              okText: '确定',
              onOk() {
                window.location.reload()
              }
            })
          })
        }
      })
    },
    handleCancel() {
      // this.getUserInfo()
      // this.readonly = true
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 24px 24px;
  /deep/.ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }
  input[readonly=true] {
    border: none;
    outline: none;
    cursor: auto;
    &:focus {
      box-shadow: none;
    }
  }
  .footer-item {
    width: 100%;
    height: 80px;
    .footer {
      position: fixed;
      z-index: 99;
      height: 80px;
      bottom: 24px;
      left: 224px;
      right: 24px;
      padding: 24px;
      background-color: #fff;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, .1);
    }
  }
}
.yyzz-wrapper /deep/ > .ant-upload {
  width: 100%;
  height: 200px;
  border: 1px dotted #d8d8d8;
  border-radius: 4px;
  background-color: #f9f9f9;
  padding: 6px;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .empty {
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #666;
    background-color: #fff;
    .icon {
      font-size: 40px;
    }
    .upload-text {
      font-size: 16px;
    }
    .upload-text-tip {
      color: #999;
      font-size: 12px;
    }
  }
}
/deep/input[readonly="true"] {
  border-color: transparent;
}
</style>